<template>
  <!-- 生成订单 -->
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item to="/user/order">我的订单</el-breadcrumb-item>
        <el-breadcrumb-item>生成订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="width: 80%; margin: 0 auto">
      <el-steps :active="active" align-center style="margin-bottom: 3rem">
        <el-step title="模型文件夹" description="选择模型文件夹"></el-step>
        <el-step title="模型文件" description="配置模型数量"></el-step>
        <el-step title="服务商与工艺" description="选择工艺和服务商"></el-step>
        <el-step title="其他" description="其他配置信息"></el-step>
      </el-steps>
      <div v-show="active === 0">
        <el-table
          :data="tableData.folders"
          ref="folderList"
          @row-click="handleRowClickOfFolder"
          class="a-table"
          v-loading="loading.folder"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <el-table-column label="文件夹名称" prop="folderName">
            <template slot-scope="scope">
              <i class="el-icon-folder"></i
              ><span style="margin-left: 0.5rem; cursor: pointer">{{
                scope.row.folderName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" prop="modelCount" />
          <el-table-column label="上传日期" prop="uploadTime" />
        </el-table>
        <div class="a-pagination">
          <el-pagination
            @size-change="handleSizeChangeOfFolder"
            @current-change="handleCurrentChangeOfFolder"
            :current-page="pageInfo.folder.currentPage"
            :page-sizes="pageInfo.folder.pageSizes"
            :page-size="pageInfo.folder.pageSize"
            :layout="pageInfo.folder.layout"
            :total="pageInfo.folder.total"
          >
          </el-pagination>
        </div>
      </div>
      <div v-show="active === 1">
        <h4
          style="margin-bottom: 1rem; color: #303133"
          v-if="folderInfo.folderName"
        >
          <i class="el-icon-folder"></i
          ><span style="margin-left: 0.5rem; cursor: pointer">{{
            folderInfo.folderName
          }}</span
          ><span>: 文件列表</span>
        </h4>
        <div style="float: right; margin-bottom: 2rem">
          <span style="font-size: 14px; color: #606266">模型默认打印数量：</span
          ><el-input
            type="number"
            :min="0"
            v-model="globalQuantity"
            style="width: 5rem; margin: 0 1rem"
          ></el-input>
          <el-button @click="handleSetGlobalQuantity" type="primary"
            >设置</el-button
          >
        </div>
        <el-table
          :data="tableData.models"
          v-loading="loading.model"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <el-table-column label="模型名称" prop="name">
            <template slot-scope="scope">
              <i class="el-icon-document"></i
              ><span style="margin-left: 0.5rem">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" prop="printCount">
            <template slot-scope="scope">
              <el-input
                type="number"
                :min="0"
                v-model="scope.row.printCount"
                style="width: 5rem"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="文件大小" prop="fileSizeMB">
            <template slot-scope="scope">
              {{ scope.row.fileSizeMB }} MB
            </template>
          </el-table-column>
          <el-table-column label="修改时间" prop="lastUpdateTime" />
          <el-table-column label="上传时间" prop="uploadTime" />
        </el-table>
      </div>
      <el-form ref="order" :model="form" label-width="120px">
        <div v-show="active === 2">
          <el-form-item label="工艺">
            <el-select
              v-model="form.technology"
              placeholder="请选择工艺"
              style="width: 100%"
              @change="handleTechChange"
            >
              <template v-for="(item, index) in technologyOptions">
                <el-option :label="item" :value="item" :key="index"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <template>
            <el-table
              ref="servicerTable"
              class="a-table"
              :data="tableData.servicer"
              :select-on-indeterminate="false"
              @row-click="handleDetailShow"
              @select="handleServicerSelect"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="公司名" prop="companyName" />
              <el-table-column label="注册地" prop="registeredAddress" />
              <el-table-column label="生产规模" prop="productionScale" />
              <el-table-column label="联系方式" prop="contact" />
            </el-table>
            <!-- 分页 -->
            <div class="a-pagination">
              <el-pagination
                @size-change="handleSizeChangeOfServicer"
                @current-change="handleCurrentChangeOfServicer"
                :current-page="pageInfo.servicer.currentPage"
                :page-sizes="pageInfo.servicer.pageSizes"
                :page-size="pageInfo.servicer.pageSize"
                :layout="pageInfo.servicer.layout"
                :total="pageInfo.servicer.total"
              >
              </el-pagination>
            </div>
          </template>
        </div>
        <div v-show="active === 3">
          <el-form-item label="打印时限">
            <template slot="label">
              <span>打印时限 </span>
              <el-popover
                placement="top-start"
                title="打印时限"
                width="320"
                trigger="hover"
              >
                <div>
                  <p>
                    为了避免用户因忘记关闭订单而可能造成的被侵权打印的风险，可以：
                  </p>
                  <p>a) 选择设定订单的打印时限；</p>
                  <p>
                    b)
                    如果订单超过设定时限还未开始生产，其指定服务商将无权限访问订单包含的三维模型数据。
                  </p>
                </div>
                <template slot="reference">
                  <i class="el-icon-question"></i>
                </template>
              </el-popover>
            </template>
            <el-input type="number" :min="0" v-model="form.deadline">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
          <el-form-item label="最大返工个数">
            <template slot="label">
              <span>最大返工个数 </span>
              <el-popover
                placement="top-start"
                title="最大返工个数"
                width="320"
                trigger="hover"
              >
                <div>
                  <p>由于打印服务存在发生打印失败的可能。</p>
                  <p>a) 设定订单的最大返工个数；</p>
                  <p>
                    b)如果三维模型打印失败，当返工个数不超过订单的最大返工个数设定时，三维模型允许被重新打印；当返工个数超过订单的最大返工个数设定时，服务商将无权限重新打印三维模型。
                  </p>
                  <p>
                    订单的最大返工个数设定在合理的数值可以降低三维模型被侵权打印的风险。返工个数超过订单的最大返工个数设定后，服务商可以与用户进行沟通，手动申请返工。
                  </p>
                </div>
                <template slot="reference">
                  <i class="el-icon-question"></i>
                </template>
              </el-popover>
            </template>
            <el-input
              type="number"
              :min="0"
              v-model="form.maxReworkCount"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="5"
              v-model="form.remarks"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div style="margin-top: 3rem; float: right">
        <el-button v-if="active > 0" @click="handlePrevStep" size="medium"
          >上一步</el-button
        >
        <el-button
          v-if="active < 3 && active > 0"
          @click="handleNextStep"
          size="medium"
          type="primary"
          :disabled="active === 2 && !serviceId"
          >下一步</el-button
        >
        <el-button
          v-if="active === 3"
          @click="handleGenerate(false)"
          size="medium"
          type="primary"
          >保存</el-button
        >
        <el-button
          v-if="active === 3"
          @click="handleGenerate(true)"
          size="medium"
          type="primary"
          >提交</el-button
        >
      </div>
    </div>
    <!-- 服务商详情 -->
    <el-dialog
      :before-close="handleDetailClose"
      :visible.sync="visible.detail"
      title="服务商详情"
      width="480px"
      :destroy-on-close="true"
    >
      <el-row class="a-detail-item"
        ><el-col :span="8">用户名：</el-col
        ><el-col :span="16">{{ servicerInfo.displayName }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">公司名：</el-col
        ><el-col :span="16">{{ servicerInfo.companyName }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">法定代表人：</el-col
        ><el-col :span="16">{{ servicerInfo.legalPerson }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">信用代码：</el-col
        ><el-col :span="16">{{ servicerInfo.creditCode }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">注册地址：</el-col
        ><el-col :span="16">{{
          servicerInfo.registeredAddress
        }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">生产规模：</el-col
        ><el-col :span="16">{{ servicerInfo.productionScale }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">联系方式：</el-col
        ><el-col :span="16">{{ servicerInfo.contact }}</el-col></el-row
      >
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      form: {},
      tableData: {
        folders: [],
        models: [],
        servicer: [],
      },
      pageInfo: {
        folder: {
          pageSizes: [20, 50, 100],
          pageSize: 20,
          layout: "total, sizes, prev, pager, next, jumper",
          currentPage: 1,
          total: 0,
        },
        servicer: {
          pageSizes: [20, 50, 100],
          pageSize: 10,
          layout: "total, sizes, prev, pager, next, jumper",
          currentPage: 1,
          total: 0,
        },
      },
      loading: {
        folder: true,
        model: true,
        servicer: true,
      },
      disabled: {
        servicer: true,
      },
      visible: {
        detail: false,
      },
      customerId: localStorage.getItem("customerId"),
      folderInfo: {},
      globalQuantity: 1,
      servicerInfo: {},
      serviceId: undefined,
      technology: undefined,
      technologyOptions: [],
    };
  },
  methods: {
    /**
     * 点击事件处理
     */
    /**模型文件夹选择 */
    handleRowClickOfFolder(row) {
      this.folderInfo = { folderId: row.id, folderName: row.folderName };
      this.active = 1;
      this.getModelOfFolder();
    },
    /**工艺选择改变 */
    handleTechChange(row) {
      this.technology = row;
      this.disabled.servicer = false;
      this.getServicers();
    },
    /**全局模型数量设置 */
    handleSetGlobalQuantity() {
      if (this.tableData.models) {
        this.tableData.models.forEach((model) => {
          model.printCount = this.globalQuantity;
        });
      }
    },
    /**上一步 */
    handlePrevStep() {
      this.tableData = {
        folders: [],
        models: [],
        servicer: [],
      };
      this.active--;
      switch (this.active) {
        case 0: {
          this.getFolderList();
          break;
        }
        case 1: {
          this.serviceId = undefined;
          this.technology = undefined;
          this.getModelOfFolder();
          break;
        }
        default:
          break;
      }
    },
    /**下一步 */
    handleNextStep() {
      this.active++;
      switch (this.active) {
        case 2: {
          this.getTechnologyOptions();
          break;
        }
        default:
          break;
      }
    },
    /**保存订单 */
    handleGenerate(isGenerate) {
      let models = JSON.parse(JSON.stringify(this.tableData.models));
      models = models.filter((model) => model.printCount > 0);
      let folders = [
        {
          folderID: this.folderInfo.folderId,
          models,
        },
      ];
      let deadline = new Date();
      deadline.setDate(deadline.getDate() + parseInt(this.form.deadline));
      folders.forEach((folder) => {
        folder.models.forEach((model) => {
          model.maxReworkCount = this.form.maxReworkCount;
        });
      });
      let params = {
        folders,
        technology: this.technology,
        customerId: this.customerId,
        manufacturerID: this.serviceId,
        deadline,
        remarks: this.form.remarks,
      };
      console.log(params);
      this.$axios.postOrder(params).then((res) => {
        if (!res.error) {
          console.log(res);
          if (isGenerate) {
            this.handleSubmit(res.id);
          } else {
            this.$message({
              message: "订单保存成功",
              type: "success",
            });
          }
          this.$router.push("/user/order");
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
      });
    },
    /**提交订单 */
    handleSubmit(orderId) {
      const customerId = this.customerId;
      if (orderId && customerId) {
        this.$axios.submitOrder({ orderId, customerId }).then((res) => {
          if (!res.error) {
            this.$message({
              message: "订单提交成功",
              type: "success",
            });
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
    /**
     * 请求方法
     */
    /**获取文件夹列表请求 */
    getFolderList() {
      this.loading.folder = true;
      let page = {
        pageIndex: this.pageInfo.folder.currentPage,
        pageSize: this.pageInfo.folder.pageSize,
      };
      this.$axios
        .getFolderList({ ...page, customerId: this.customerId })
        .then((res) => {
          if (!res.error) {
            this.pageInfo.folder.total = res.totalCount;
            this.tableData.folders = res.data.map((folder) => {
              return {
                folderName: folder.folderName,
                id: folder.id,
                modelCount: folder.modelCount,
                models: [],
                uploadTime: this.$utils.dateToString(folder.uploadTime),
              };
            });
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading.folder = false;
        });
    },
    /**获取文件夹下模型文件列表请求 */
    getModelOfFolder() {
      this.loading.model = true;
      const folderId = this.folderInfo.folderId;
      if (folderId) {
        this.$axios.getModelOfFolder({ folderId }).then((res) => {
          if (!res.error) {
            this.pageInfo.folder.total = res.totalCount;
            this.tableData.models = res.data.map((model) => {
              return {
                ...model,
                printCount: 1,
                fileSize: (((Math.random() * 1000) % 30) + 20).toFixed(2) + "M",
                uploadTime: this.$utils.dateToString(model.uploadTime),
              };
            });
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading.model = false;
        });
      }
    },
    /**获取服务商列表请求 */
    getServicers() {
      this.loading.servicer = true;
      let printTechnology = this.technology;
      let page = {
        pageIndex: this.pageInfo.folder.currentPage,
        pageSize: this.pageInfo.folder.pageSize,
      };
      this.$axios
        .getServicersByTechnology({ ...page, printTechnology })
        .then((res) => {
          if (!res.error) {
            this.pageInfo.servicer.total = res.totalCount;
            this.tableData.servicer = res.data;
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading.servicer = false;
        });
    },
    /**获取工艺列表请求 */
    getTechnologyOptions() {
      this.technology = undefined;
      this.$axios.getTechnologyOptions().then((res) => {
        if (!res.error) {
          this.technologyOptions = res;
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
      });
    },
    /**文件夹分页 */
    // 页面尺寸改变
    handleSizeChangeOfFolder(val) {
      this.pageInfo.folder.pageSize = val;
      this.getFolderList();
    },
    // 当前页改变
    handleCurrentChangeOfFolder(val) {
      this.pageInfo.folder.currentPage = val;
      this.getFolderList();
    },
    /**服务商分页 */
    // 页面尺寸改变
    handleSizeChangeOfServicer(val) {
      this.pageInfo.servicer.pageSize = val;
      this.getServicers();
    },
    // 当前页改变
    handleCurrentChangeOfServicer(val) {
      this.pageInfo.servicer.currentPage = val;
      this.getServicers();
    },
    /**服务商选择 */
    handleServicerSelect(selection, row) {
      this.$refs.servicerTable.clearSelection();
      this.$refs.servicerTable.toggleRowSelection(row, true);
      this.$refs.servicerTable.setCurrentRow(row);
      this.serviceId = row.id;
    },
    /**服务商详情 */
    handleDetailShow(row) {
      event.preventDefault();
      // console.log(row, col, e);
      this.servicerInfo = row;
      this.visible.detail = true;
    },
    handleDetailClose() {
      this.servicerInfo = {};
      this.visible.detail = false;
    },
  },
  mounted() {
    this.getFolderList();
  },
};
</script>

<style lang="scss" scoped>
.a-detail-item {
  margin: 0.5rem 0;
}
.a-detail-operate {
  margin-top: 2rem;
  text-align: center;
}
</style>

<style lang="scss">
.el-table-column--selection.is-leaf.el-table__cell .el-checkbox {
  display: none;
}
</style>
